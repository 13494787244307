import { render, staticRenderFns } from "./ThgMyVehicleDecommissionDialog.vue?vue&type=template&id=2707fe26&"
import script from "./ThgMyVehicleDecommissionDialog.vue?vue&type=script&lang=ts&"
export * from "./ThgMyVehicleDecommissionDialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VForm } from 'vuetify/lib/components/VForm';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VForm,VRadio,VRadioGroup,VTextField,VTextarea})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2707fe26')) {
      api.createRecord('2707fe26', component.options)
    } else {
      api.reload('2707fe26', component.options)
    }
    module.hot.accept("./ThgMyVehicleDecommissionDialog.vue?vue&type=template&id=2707fe26&", function () {
      api.rerender('2707fe26', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/thg/ThgMyVehicleDecommissionDialog.vue"
export default component.exports