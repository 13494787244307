var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-sheet",
    {
      staticClass: "mx-4 mb-2 grey lighten-3",
      attrs: {
        rounded: ""
      }
    },
    [
      _c(
        "v-row",
        {
          attrs: {
            "no-gutters": "",
            align: "start"
          }
        },
        [
          _c(
            "v-col",
            {
              attrs: {
                cols: "12",
                md: "auto"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "ma-4"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "font-weight-black"
                    },
                    [_vm._v(_vm._s(_vm.thg.year))]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "text-caption"
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "components.thg.ThgMyVehicleThgQuoteDetail.thgPremium"
                          )
                        )
                      )
                    ]
                  )
                ]
              )
            ]
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "12",
                md: "auto"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "ma-4"
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: {
                            left: "",
                            color: _vm.thgStatus.color
                          }
                        },
                        [_vm._v("mdi-check-circle")]
                      ),
                      _vm._v(" " + _vm._s(_vm.$t(_vm.thgStatus.textAlt)) + " ")
                    ],
                    1
                  ),
                  _vm.historyEnabled
                    ? _c(
                        "div",
                        {
                          staticClass: "text-caption",
                          on: {
                            click: _vm.showDetailInfos
                          }
                        },
                        [
                          !_vm.showInfos
                            ? _c(
                                "span",
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "components.thg.ThgMyVehicleThgQuoteDetail.showMoreInfo"
                                        )
                                      ) +
                                      " "
                                  ),
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        left: "",
                                        "x-small": ""
                                      }
                                    },
                                    [_vm._v("mdi-chevron-down")]
                                  )
                                ],
                                1
                              )
                            : _c(
                                "span",
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "components.thg.ThgMyVehicleThgQuoteDetail.showLessInfo"
                                        )
                                      ) +
                                      " "
                                  ),
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        left: "",
                                        "x-small": ""
                                      }
                                    },
                                    [_vm._v("mdi-chevron-up")]
                                  )
                                ],
                                1
                              )
                        ]
                      )
                    : _vm._e(),
                  _vm.showInfos
                    ? _c(
                        "v-stepper",
                        {
                          staticClass: "mt-4",
                          attrs: {
                            vertical: "",
                            elevation: "0"
                          },
                          model: {
                            value: _vm.step,
                            callback: function callback($$v) {
                              _vm.step = $$v
                            },
                            expression: "step"
                          }
                        },
                        [
                          _c(
                            "v-stepper-step",
                            {
                              attrs: {
                                step: "1"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "components.thg.ThgMyVehicleThgQuoteDetail.step1Main",
                                      {
                                        year: _vm.thg.year
                                      }
                                    )
                                  ) +
                                  " "
                              ),
                              _c("small", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "components.thg.ThgMyVehicleThgQuoteDetail.step1Small"
                                    )
                                  )
                                )
                              ])
                            ]
                          ),
                          _c(
                            "v-stepper-content",
                            {
                              attrs: {
                                step: "1"
                              }
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "mb-12",
                                  attrs: {
                                    elevation: "0"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "components.thg.ThgMyVehicleThgQuoteDetail.step1Card"
                                        )
                                      )
                                  ),
                                  _c("br"),
                                  _vm._v(" " + _vm._s(_vm.thg.id) + " ")
                                ]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "components.thg.ThgMyVehicleThgQuoteDetail.uploadVehicleCertificate"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    text: ""
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "components.thg.ThgMyVehicleThgQuoteDetail.inquiries"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-stepper-step",
                            {
                              attrs: {
                                step: "2"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "components.thg.ThgMyVehicleThgQuoteDetail.step2"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c("v-stepper-content", {
                            attrs: {
                              step: "2"
                            }
                          }),
                          _c(
                            "v-stepper-step",
                            {
                              attrs: {
                                step: "3"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "components.thg.ThgMyVehicleThgQuoteDetail.step3Main"
                                    )
                                  ) +
                                  " "
                              ),
                              _c("small", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "components.thg.ThgMyVehicleThgQuoteDetail.step3Small"
                                    )
                                  )
                                )
                              ])
                            ]
                          ),
                          _c("v-stepper-content", {
                            attrs: {
                              step: "3"
                            }
                          }),
                          _c(
                            "v-stepper-step",
                            {
                              attrs: {
                                step: "4"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "components.thg.ThgMyVehicleThgQuoteDetail.step4"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c("v-stepper-content", {
                            attrs: {
                              step: "4"
                            }
                          }),
                          _c(
                            "v-stepper-step",
                            {
                              attrs: {
                                step: "5"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "components.thg.ThgMyVehicleThgQuoteDetail.step5"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c("v-stepper-content", {
                            attrs: {
                              step: "5"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          ),
          _vm.priceEnabled
            ? _c(
                "v-col",
                {
                  staticClass: "d-md-flex ml-auto",
                  attrs: {
                    cols: "12",
                    md: "auto"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "ma-4 text-right"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "font-weight-black"
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.price.priceAndPromotionAsString) +
                              " "
                          )
                        ]
                      ),
                      _vm.thg.promotionConfiguration && _vm.price.price
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "text-caption green--text text--darken-4"
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "components.thg.ThgMyVehicleThgQuoteDetail.promotionBonus",
                                      {
                                        bonusValue:
                                          _vm.thg.promotionConfiguration.value
                                      }
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  )
                ]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }