var render = function() {
  var _vm$vehicle

  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      staticClass: "mb-6 pb-4"
    },
    [
      _c(
        "div",
        {
          staticClass: "px-4 pt-4 d-flex align-center"
        },
        [
          _c(
            "div",
            {
              staticClass: "flex-grow-1"
            },
            [
              _c("numberplate-form", {
                attrs: {
                  value: _vm.numberplate,
                  disabled: true
                }
              })
            ],
            1
          ),
          _vm.vehicle &&
          !(
            (_vm$vehicle = _vm.vehicle) !== null &&
            _vm$vehicle !== void 0 &&
            _vm$vehicle.decommissioningDate
          )
            ? _c(
                "v-menu",
                {
                  attrs: {
                    "offset-y": ""
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function fn(_ref) {
                          var on = _ref.on,
                            attrs = _ref.attrs
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    attrs: {
                                      icon: ""
                                    }
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [_c("v-icon", [_vm._v("mdi-dots-vertical")])],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3221905750
                  )
                },
                [
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item",
                        {
                          on: {
                            click: _vm.openDecommissioningDialog
                          }
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "components.thg.ThgMyVehicle.vehicleUnregister"
                                )
                              )
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("thg-my-vehicle-decommission-dialog", {
        ref: "decommisionDialog",
        attrs: {
          thgs: _vm.thgs,
          vehicle: _vm.vehicle
        }
      }),
      _c("thg-my-vehicle-registration-detail", {
        attrs: {
          vehicle: _vm.vehicle,
          thgs: _vm.thgs
        }
      }),
      _c("thg-my-vehicle-renewal-options-sheet", {
        attrs: {
          vehicle: _vm.vehicle,
          thgs: _vm.thgs
        }
      }),
      _vm._l(_vm.thgs, function(thg) {
        return _c("thg-my-vehicle-thg-quote-detail", {
          key: thg.id,
          attrs: {
            thg: thg
          }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }