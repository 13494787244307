var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _vm._t("activator", null, {
        on: _vm.open
      }),
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            isDialogActive: _vm.dialogModel,
            rightDisabled: !_vm.validForm,
            rightLoading: _vm.isLoading,
            leftDisabled: _vm.isLoading,
            hideClose: _vm.isLoading,
            rightColor: "error",
            rightText: _vm.$t(
              "components.thg.ThgMyVehicleDecommissionDialog.rightText"
            ),
            title: _vm.$t("components.thg.ThgMyVehicleDecommissionDialog.title")
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.dialogModel = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.dialogModel = $event
            },
            close: _vm.close,
            rightClick: _vm.decommissionVehicle,
            leftClick: _vm.close
          }
        },
        [
          _c(
            "v-form",
            {
              model: {
                value: _vm.validForm,
                callback: function callback($$v) {
                  _vm.validForm = $$v
                },
                expression: "validForm"
              }
            },
            [
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "components.thg.ThgMyVehicleDecommissionDialog.question"
                    )
                  )
                )
              ]),
              _c(
                "v-radio-group",
                {
                  attrs: {
                    rules: _vm.requiredRule,
                    column: "",
                    disabled: _vm.isLoading
                  },
                  model: {
                    value: _vm.ratingEntity.key,
                    callback: function callback($$v) {
                      _vm.$set(_vm.ratingEntity, "key", $$v)
                    },
                    expression: "ratingEntity.key"
                  }
                },
                [
                  _c("v-radio", {
                    attrs: {
                      label: _vm.$t(
                        "components.thg.ThgMyVehicleDecommissionDialog.optionVehicleSold"
                      ),
                      value: _vm.VehicleDecommissionReasonEnum.VEHICLE_SOLD
                    }
                  }),
                  _c("v-radio", {
                    attrs: {
                      label: _vm.$t(
                        "components.thg.ThgMyVehicleDecommissionDialog.optionVehicleDamaged"
                      ),
                      value: _vm.VehicleDecommissionReasonEnum.VEHICLE_DAMAGED
                    }
                  }),
                  _c("v-radio", {
                    attrs: {
                      label: _vm.$t(
                        "components.thg.ThgMyVehicleDecommissionDialog.optionUnsatisfiedWithService"
                      ),
                      value:
                        _vm.VehicleDecommissionReasonEnum
                          .UNSATISFIED_WITH_SERVICE
                    }
                  }),
                  _c("v-radio", {
                    attrs: {
                      label: _vm.$t(
                        "components.thg.ThgMyVehicleDecommissionDialog.optionOther"
                      ),
                      value: _vm.VehicleDecommissionReasonEnum.OTHER
                    }
                  })
                ],
                1
              ),
              _vm.askForDate
                ? _c("v-text-field", {
                    attrs: {
                      disabled: _vm.isLoading,
                      type: "date",
                      label: _vm.$t(
                        "components.thg.ThgMyVehicleDecommissionDialog.decommissionDateLabel"
                      ),
                      hint: _vm.$t(
                        "components.thg.ThgMyVehicleDecommissionDialog.decommissionDateHint"
                      ),
                      rules: _vm.requiredRule,
                      outlined: "",
                      required: ""
                    },
                    model: {
                      value: _vm.decommissioningDate,
                      callback: function callback($$v) {
                        _vm.decommissioningDate = $$v
                      },
                      expression: "decommissioningDate"
                    }
                  })
                : _vm.ratingEntity.key
                ? _c("v-textarea", {
                    attrs: {
                      disabled: _vm.isLoading,
                      rules: _vm.requiredRule,
                      label: _vm.textAreaLabel,
                      outlined: ""
                    },
                    model: {
                      value: _vm.ratingEntity.message,
                      callback: function callback($$v) {
                        _vm.$set(_vm.ratingEntity, "message", $$v)
                      },
                      expression: "ratingEntity.message"
                    }
                  })
                : _vm._e(),
              _vm.ratingEntity.key
                ? _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "components.thg.ThgMyVehicleDecommissionDialog.importantNote"
                        )
                      )
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }