var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "confirm-action-dialog",
    {
      attrs: {
        isDialogActive: _vm.dialogModel,
        absolute: true,
        title: _vm.$t("views.fleet.FleetHomeVehicleTable.importVehicle"),
        rightDisabled: _vm.rightDisabled,
        leftDisabled: _vm.leftDisabled,
        rightText: _vm.rightText,
        leftText: _vm.$t("back"),
        persistent: true
      },
      on: {
        "update:isDialogActive": function updateIsDialogActive($event) {
          _vm.dialogModel = $event
        },
        "update:is-dialog-active": function updateIsDialogActive($event) {
          _vm.dialogModel = $event
        },
        close: _vm.close,
        leftClick: _vm.prev,
        rightClick: _vm.next
      }
    },
    [
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            isDialogActive: _vm.isShowImagePreviewDialog,
            title: _vm.$t("components.template.dialog.preview.preview"),
            rightText: _vm.$t("ok"),
            hideLeft: true
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.isShowImagePreviewDialog = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.isShowImagePreviewDialog = $event
            },
            rightClick: _vm.closeImagePreview,
            close: _vm.closeImagePreview
          }
        },
        [
          _c("v-img", {
            staticClass: "align-end ma-auto",
            style: "max-height: calc(100vh - ".concat(
              _vm.$vuetify.breakpoint.smAndDown ? "225px" : "300px",
              ");"
            ),
            attrs: {
              contain: "",
              src: _vm.imagePreview
            }
          })
        ],
        1
      ),
      _c(
        "v-window",
        {
          model: {
            value: _vm.window,
            callback: function callback($$v) {
              _vm.window = $$v
            },
            expression: "window"
          }
        },
        [
          _c(
            "v-window-item",
            {
              attrs: {
                value: _vm.ThgImportEnum.SELECT
              }
            },
            [
              _c(
                "p",
                {
                  staticClass: "text-subtitle-2 mb-0"
                },
                [_vm._v(_vm._s(_vm.$t("report.thgYearSelector.title")))]
              ),
              _c(
                "p",
                {
                  staticClass: "text-body-2 mb-0"
                },
                [_vm._v(_vm._s(_vm.$t("report.thgYearSelector.description")))]
              ),
              _c("thg-dialog-year-selection", {
                staticClass: "pt-n4",
                attrs: {
                  numberplate: "",
                  possibleYears: _vm.years,
                  showText: false
                }
              })
            ],
            1
          ),
          _c(
            "v-window-item",
            {
              attrs: {
                value: _vm.ThgImportEnum.DROP
              }
            },
            [
              _c(
                "p",
                {
                  staticClass: "text-subtitle-2 mb-0"
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "report.thgVehicleOverview.thgImportDialog.dropTitle"
                      )
                    )
                  )
                ]
              ),
              _c("p", {
                staticClass: "text-body-2 mb-0",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t(
                      "report.thgVehicleOverview.thgImportDialog.dropSubtitle"
                    )
                  )
                }
              }),
              _c("drop-area-multi", {
                staticClass: "my-2",
                attrs: {
                  files: _vm.files,
                  text: _vm.$t(
                    "report.thgVehicleOverview.thgImportDialog.dragOver"
                  ),
                  dragoverText: _vm.$t(
                    "report.thgVehicleOverview.thgImportDialog.dragOver"
                  ),
                  accept: _vm.accept,
                  maxFiles: _vm.maxFiles
                },
                on: {
                  dropped: _vm.prepareDtos,
                  "update:files": function updateFiles($event) {
                    _vm.files = $event
                  }
                }
              })
            ],
            1
          ),
          _c(
            "v-window-item",
            {
              attrs: {
                value: _vm.ThgImportEnum.CONFIRM
              }
            },
            [
              _c(
                "p",
                {
                  staticClass: "text-subtitle-2 mb-0"
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "report.thgVehicleOverview.thgImportDialog.confirmTitle"
                      )
                    )
                  )
                ]
              ),
              _c("p", {
                staticClass: "text-body-2 mb-0",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t(
                      "report.thgVehicleOverview.thgImportDialog.confirmSubtitle"
                    )
                  )
                }
              }),
              _c("v-virtual-scroll", {
                attrs: {
                  items: _vm.thgs,
                  "item-height": 64,
                  height: "475"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function fn(_ref) {
                      var index = _ref.index,
                        item = _ref.item
                      return [
                        _c(
                          "v-list-item",
                          {
                            key: index,
                            staticClass: "px-0"
                          },
                          [
                            _c(
                              "v-list-item-avatar",
                              {
                                attrs: {
                                  tile: ""
                                },
                                on: {
                                  click: function click($event) {
                                    return _vm.showImagePreview(item)
                                  }
                                }
                              },
                              [
                                _c("v-img", {
                                  key: "image" + index,
                                  attrs: {
                                    src: item.imageUrl,
                                    "max-height": "50",
                                    "max-width": "50"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-content",
                              {
                                staticClass: "pr-4"
                              },
                              [
                                _c(
                                  "v-list-item-title",
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        dense: "",
                                        "single-line": "",
                                        "hide-details": "",
                                        disabled:
                                          item.isUploaded || item.isLoading,
                                        loading: item.isLoading,
                                        label: "Numberplate"
                                      },
                                      model: {
                                        value: item.dto.numberplate,
                                        callback: function callback($$v) {
                                          _vm.$set(item.dto, "numberplate", $$v)
                                        },
                                        expression: "item.dto.numberplate"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("v-list-item-subtitle", [
                                  _vm._v(" " + _vm._s(item.file.name) + " "),
                                  item.page
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "report.thgVehicleOverview.thgImportDialog.page",
                                                {
                                                  page: item.page
                                                }
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              ],
                              1
                            ),
                            item.isUploaded
                              ? _c("v-list-item-action", [
                                  _c(
                                    "span",
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "report.thgVehicleOverview.thgImportDialog.submitted"
                                            )
                                          ) +
                                          " "
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            small: "",
                                            color: "success"
                                          }
                                        },
                                        [_vm._v(" mdi-check ")]
                                      )
                                    ],
                                    1
                                  )
                                ])
                              : _vm._e(),
                            item.isLoading && !item.isUploaded
                              ? _c(
                                  "v-list-item-action",
                                  [
                                    _c("v-progress-circular", {
                                      attrs: {
                                        indeterminate: "",
                                        size: 20,
                                        width: 3
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            !item.isLoading &&
                            !item.isUploaded &&
                            _vm.isSingleRequestPossible
                              ? _c(
                                  "v-list-item-action",
                                  [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: {
                                          bottom: ""
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function fn(_ref2) {
                                                var on = _ref2.on,
                                                  attrs = _ref2.attrs
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            small: ""
                                                          },
                                                          on: {
                                                            click: function click(
                                                              $event
                                                            ) {
                                                              return _vm.submitThg(
                                                                item
                                                              )
                                                            }
                                                          }
                                                        },
                                                        "v-icon",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [_vm._v(" mdi-check ")]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "report.thgVehicleOverview.thgImportDialog.singleRequest"
                                                )
                                              ) +
                                              " "
                                          )
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            !item.isLoading && !item.isUploaded
                              ? _c(
                                  "v-list-item-action",
                                  [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: {
                                          bottom: ""
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function fn(_ref3) {
                                                var on = _ref3.on,
                                                  attrs = _ref3.attrs
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass: "mr-2",
                                                          attrs: {
                                                            small: "",
                                                            tabindex: "-1"
                                                          },
                                                          on: {
                                                            click: function click(
                                                              $event
                                                            ) {
                                                              return _vm.popItem(
                                                                index
                                                              )
                                                            }
                                                          }
                                                        },
                                                        "v-icon",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [_vm._v(" mdi-delete ")]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            " " + _vm._s(_vm.$t("remove")) + " "
                                          )
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "v-window-item",
            {
              attrs: {
                value: _vm.ThgImportEnum.UPLOAD
              }
            },
            [
              _c("v-virtual-scroll", {
                attrs: {
                  items: _vm.thgs,
                  "item-height": 64,
                  height: "576"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function fn(_ref4) {
                      var index = _ref4.index,
                        item = _ref4.item
                      return [
                        _c(
                          "v-list-item",
                          {
                            key: index
                          },
                          [
                            _c(
                              "v-list-item-avatar",
                              {
                                attrs: {
                                  tile: ""
                                },
                                on: {
                                  click: function click($event) {
                                    return _vm.showImagePreview(item)
                                  }
                                }
                              },
                              [
                                _c("v-img", {
                                  attrs: {
                                    src: item.imageUrl,
                                    "max-height": "50",
                                    "max-width": "50"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-content",
                              {
                                staticClass: "pr-4"
                              },
                              [
                                _c("v-list-item-title", [
                                  _vm._v(
                                    " " + _vm._s(item.dto.numberplate) + " "
                                  )
                                ]),
                                _c("v-list-item-subtitle", [
                                  _vm._v(" " + _vm._s(item.file.name) + " "),
                                  item.page
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "report.thgVehicleOverview.thgImportDialog.page",
                                                {
                                                  page: item.page
                                                }
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    : _vm._e()
                                ]),
                                item.isError
                                  ? _c(
                                      "v-list-item-subtitle",
                                      {
                                        staticClass: "red--text"
                                      },
                                      [_vm._v(" " + _vm._s(item.error) + " ")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            item.isUploaded
                              ? _c("v-list-item-action", [
                                  _c(
                                    "span",
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "report.thgVehicleOverview.thgImportDialog.submitted"
                                            )
                                          ) +
                                          " "
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            small: "",
                                            color: "success"
                                          }
                                        },
                                        [_vm._v(" mdi-check ")]
                                      )
                                    ],
                                    1
                                  )
                                ])
                              : _vm._e(),
                            item.isLoading && !item.isUploaded
                              ? _c(
                                  "v-list-item-action",
                                  [
                                    _c("v-progress-circular", {
                                      attrs: {
                                        indeterminate: "",
                                        size: 20,
                                        width: 3
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.isError
                              ? _c(
                                  "v-list-item-action",
                                  [
                                    _c(
                                      "span",
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("views.Error.title")
                                            ) +
                                            " "
                                        ),
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              color: "error"
                                            }
                                          },
                                          [_vm._v(" mdi-alert ")]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          small: "",
                                          flat: "",
                                          text: "",
                                          outlined: ""
                                        },
                                        on: {
                                          click: function click($event) {
                                            return _vm.submitThg(item)
                                          }
                                        }
                                      },
                                      [_vm._v(" " + _vm._s(_vm.$t("retry")))]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "v-window-item",
            {
              attrs: {
                value: _vm.ThgImportEnum.SUCCESS
              }
            },
            [
              _c(
                "v-card",
                {
                  attrs: {
                    flat: ""
                  }
                },
                [
                  _c(
                    "v-card-text",
                    [
                      _c("v-img", {
                        staticClass: "mt-8",
                        attrs: {
                          align: "center",
                          justify: "center",
                          contain: "",
                          "max-height": "250px",
                          src: require("@/assets/undraw/undraw_mail_sent_re_0ofv.svg")
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-card-title", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "report.thgVehicleOverview.thgImportDialog.imported",
                            {
                              count: _vm.uploadedThgCount,
                              years: _vm.selectedYears.join(" & ")
                            }
                          )
                        ) +
                        " "
                    )
                  ]),
                  _c("v-card-subtitle", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "report.thgVehicleOverview.thgImportDialog.subtitle"
                          )
                        ) +
                        " "
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }