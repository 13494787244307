var render = function() {
  var _vm$vehicle, _vm$vehicle2

  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c("v-subheader", [
        !(
          (_vm$vehicle = _vm.vehicle) !== null &&
          _vm$vehicle !== void 0 &&
          _vm$vehicle.identificationnumber
        )
          ? _c("span", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "components.thg.ThgMyVehicleRegistrationDetail.vehicleDataUnderReview"
                    )
                  ) +
                  " "
              )
            ])
          : _c("span", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "components.thg.ThgMyVehicleRegistrationDetail.vehicleCreated",
                      {
                        identificationnumber: _vm.vehicle.identificationnumber,
                        createdReadable:
                          (_vm$vehicle2 = _vm.vehicle) === null ||
                          _vm$vehicle2 === void 0
                            ? void 0
                            : _vm$vehicle2.timestamp.createdReadable
                      }
                    )
                  ) +
                  " "
              )
            ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }